import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { BackgroundColor, TextColor } from '@lib/colors'

export const IconCircle = ({
  backgroundColor,
  className,
  icon,
  iconColor = 'text-success600',
}: {
  backgroundColor?: BackgroundColor
  className?: string
  icon: IconDefinition
  iconColor?: TextColor
}) => (
  <div
    className={classMerge(
      'flex',
      'rounded-full',
      'h-6',
      'w-6',
      backgroundColor ?? 'bg-success200',
      'items-center',
      'justify-center',
      className,
    )}
  >
    <Icon className={classMerge('text-sm', iconColor)} icon={icon} />
  </div>
)

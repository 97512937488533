import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import type { BackgroundColor } from '@lib/colors'
import { IconCircle } from '../IconCircle'

export const CheckCircle = ({
  backgroundColor,
  className,
}: {
  backgroundColor?: BackgroundColor
  className?: string
}) => (
  <IconCircle
    backgroundColor={backgroundColor}
    className={className}
    icon={faCheck}
  />
)

import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { BackgroundColor } from '@lib/colors'

import { CheckCircle } from './CheckCircle'

export const CustomSelectOption = ({
  checkCircleColor,
  className,
  label,
  selectedValue,
  showCheckCircle,
  value,
}: {
  checkCircleColor?: BackgroundColor
  className?: string
  label: string
  selectedValue?: string
  showCheckCircle: boolean
  value: string
}) => {
  const isSelected = value === selectedValue

  return (
    <div
      className={classMerge(
        'flex',
        'items-center',
        'justify-between',
        'cursor-pointer',
      )}
    >
      <Text className={className} value={label} />
      {showCheckCircle && isSelected && (
        <CheckCircle backgroundColor={checkCircleColor} />
      )}
    </div>
  )
}

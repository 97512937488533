import algoliasearch from 'algoliasearch/lite'
import { getAlgoliaSearchIndex } from './getAlgoliaSearchIndex'

export const ALGOLIA_APP_ID = '02DS8KORFW'

const algoliaSearchApiKey = '2b452b26d800144dd07fb695f53fc213' // this is a public key

/**
 * Public read client entry point for Algolia Search, using the `lite` framework.
 */
export const algoliaReadClient = algoliasearch(
  ALGOLIA_APP_ID,
  algoliaSearchApiKey,
)

/**
 * Algolia-based search, filter, and facet based on indices. Publicly readable.
 */
export const search = {
  trialsBySiteLocations: algoliaReadClient.initIndex(
    getAlgoliaSearchIndex('trials-by-site-locations'),
  ),
  zipcodes: algoliaReadClient.initIndex(getAlgoliaSearchIndex('zipcodes')),
}

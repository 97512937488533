import { Icon } from '@components/primitives/Icon'
import Input, { type InputProps } from '@components/primitives/Input'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck'
import type { BorderColor } from '@lib/colors'
import validateEmailFormat from '@lib/utilities/input/validateEmailFormat'
import emailSpellChecker from '@zootools/email-spell-checker'
import { useEffect, useState } from 'react'

function getInputBorderColor({
  focused,
  isEmpty,
  isFilledButInvalid,
  isValid,
  neutralBorderColor = 'border-neutral300',
}: {
  focused: boolean
  isEmpty: boolean
  isFilledButInvalid: boolean
  isValid: boolean
  neutralBorderColor?: BorderColor
}) {
  if (isEmpty) {
    return neutralBorderColor
  }

  if (isFilledButInvalid) {
    return 'border-destructive300'
  }

  if (isValid || focused) {
    return 'border-success300'
  }

  return neutralBorderColor
}

type EmailInputProps = InputProps & {
  containerClassname?: string
  email?: string // pseudo-controlled component
  hideSuggestions?: boolean
  neutralBorderColor?: BorderColor
  setValue: (e: string) => void
  value?: string
}

export function EmailInput({
  className,
  containerClassname,
  email,
  hideSuggestions,
  neutralBorderColor,
  placeholder,
  setValue,
  value,
  ...inputProps
}: EmailInputProps) {
  value = value ?? email // Pseudo-controlled component input value state to show typos
  const [focused, setFocus] = useState(false)

  const [suggestedEmail, setSuggestedEmail] = useState(
    emailSpellChecker.run({ email: value ?? '' }),
  )
  const hasSuggestedEmail = Boolean(suggestedEmail)
  const isValid = hasSuggestedEmail || validateEmailFormat(value ?? '')
  const isFilledButInvalid = (value?.length ?? 0) > 6 && !isValid
  const isEmpty = (value?.length ?? 0) === 0

  const inputBorderColor = getInputBorderColor({
    focused,
    isEmpty,
    isFilledButInvalid,
    isValid,
    neutralBorderColor,
  })

  const classes = classMerge(
    'w-full rounded-full border-2 bg-lightGrey700',
    'h-16',
    inputBorderColor,
    'shadow-none', // remove default drop shadow style
    className,
  )

  useEffect(() => {
    if (!hideSuggestions) {
      const getSuggestedEmail = setTimeout(() => {
        setSuggestedEmail(emailSpellChecker.run({ email: value ?? '' }))
      }, 1000)

      return () => clearTimeout(getSuggestedEmail)
    }
  }, [value])

  return (
    <div className={classMerge('grid grid-cols-1 gap-4', containerClassname)}>
      <Input
        {...inputProps}
        className={classes}
        error={!isEmpty && (!isValid || isFilledButInvalid)}
        onBlur={() => setFocus(false)}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setFocus(true)}
        placeholder={placeholder}
        type='email'
        value={value}
      />
      {suggestedEmail && !hideSuggestions && (
        <div className='flex flex-row items-center justify-start gap-1'>
          <Text
            className={'text-neutral600'}
            element='p'
            styleName='p-small-strong'
            value='Did you mean'
          />
          <div className='flex flex-row items-center justify-start gap-1 rounded-full bg-success100 px-2'>
            <Text
              className={'ml-1 text-success900'}
              element='p'
              onClick={() => setValue(suggestedEmail.full)}
              styleName='p-small-strong'
              value={`${suggestedEmail.full}`}
            />
            <Icon className='mx-1 text-success500' icon={faCircleCheck} />
          </div>
          <Text
            className={'text-neutral600'}
            element='p'
            styleName='p-small-strong'
            value='?'
          />
        </div>
      )}
    </div>
  )
}
